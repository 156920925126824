import logo from './logo.svg';
import './App.css';
import { useEffect } from 'react';

function App() {

  function RedirectAzucenas() {
    useEffect(() => {
      // Get the current URL path
      const currentPath = window.location.pathname;

      // Redirect to azucenas.net with the same path
      window.location.href = `https://azucenas.net${currentPath}`;
    }, []);

    return null; // This component doesn't render anything
  }

  return (
    <div className="App">
      <RedirectAzucenas />
    </div>
  );
}

export default App;
